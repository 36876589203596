export const useTokenView = async () => {
  return await $fetch('/api/token', {
    method: 'GET',
    redirect: 'manual'
  }).catch((err: any) => {
    return err.response
  })
}

export const useToken = async () => {
  return await $fetch('/api/token/all', {
    method: 'GET',
    redirect: 'manual'
  }).catch((err) => {
    return err.response
  })
}

export const useNewToken = async (amount: number) => {
  const resp = await $fetch('/api/token', {
    method: 'POST',
    redirect: 'manual',
    body: JSON.stringify({
      amount
    })
  }).catch((err: any) => {
    return err.response
  })

  if (resp.status === 200) {
    return resp.json()
  }
}

export const useDeleteToken = async (tokens: string[]) => {
  const resp = await $fetch('/api/token/delete', {
    method: 'POST',
    redirect: 'manual',
    body: JSON.stringify({
      tokens
    })
  }).catch((err: any) => {
    return err.response
  })
}

export const useDeleteAllTokens = async (amount: number) => {
  const resp = await $fetch('/api/token', {
    method: 'POST',
    redirect: 'manual',
    body: JSON.stringify({
      amount,
      delete: true
    })
  }).catch((err: any) => {
    return err.response
  })

  if (resp.status === 200) {
    return resp.json()
  }
}

export const useActivateToken = async (e: any) => {
  if (!(e.target instanceof HTMLFormElement)) { return 0 }
  const formData = new FormData(e.target)

  return await $fetch('/api/token/activate', {
    method: 'POST',
    body: {
      token: formData.get('token'),
      hubid: formData.get('hubid')
    },
    redirect: 'manual' // ignore redirect responses
  })
    .catch((err) => {
      return err.status
    })
}

export const useResetToken = async (token: string) => {
  return await $fetch('/api/admin/reset', {
    method: 'POST',
    body: {
      token
    },
    redirect: 'manual' // ignore redirect responses
  })
    .catch((err) => {
      return err.status
    })
}

export interface TokenView {
  token_count?: number
  pending_count?: number
  sold_count?: number
  active_count?: number
  redeemed_count?: number
  inshipment_count?: number
  atshop_count?: number
  inrepair_count?: number
  toreturn_count?: number
  inreturn_count?: number
  received_count?: number
  revoked_count?: number
}

export const useGetTokenTimeline = async (tokenId: string) => {
  console.log('useGetTokenTimeline', tokenId)
  return await $fetch(`/api/token/timeline?tokenId=${tokenId}`, {
    method: 'GET',
    redirect: 'manual'
  }).catch((err: any) => {
    return err.response
  })
}

export const useCheckToken = async (token: string) => {
  return await $fetch(`/api/token/check?token=${token}`, {
    method: 'GET',
    redirect: 'manual'
  }).catch((err: any) => {
    return err.response
  })
}
